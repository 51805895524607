import React from "react"
import { Link } from "gatsby"
import { Menu } from "@headlessui/react"

import MenuLine from "./menu-line"
import MenuLineMuted from "./menu-line-muted"
import MenuLarge from "./menu-large"
import Logo from "./logo"

import "./layout.css"

function Layout({ children }) {
  return (
    <div className="flex flex-col h-screen z-0">
      {/* Heading */}
      <div className="flex-none">
        <div className="container mx-auto px-4 flex justify-between md:justify-start">
          <Link className="z-0" to="/"><Logo><div to="/">Jølster Adventure</div></Logo></Link>
          <MenuLarge></MenuLarge>
          <Menu className="flex flex-col items-top">
            {({ open }) => (
              <div>
                <div className="md:hidden h-14 items-center py-4">
                  <Menu.Button className={open ? "hidden" : "block"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </Menu.Button>
                </div>
                <div className="self-end md:hidden absolute pt-4">
                  <div className={open ? "block" : "hidden"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
                <div className="bg-blue-300">
                  <Menu.Items className="md:hidden pt-3 text-green-900 absolute right-4 top-12 border rounded shadow bg-green-50">
                    <Menu.Item>
                      {({ active }) => (
                        <Link to="/stisykling/">
                          <MenuLine>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4 mr-2 inline"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Stisykling
                          </MenuLine>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link to="/topptur/">
                          <MenuLine>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4 mr-2 inline"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                clip-rule="evenodd"
                              />
                            </svg>
                            Topptur
                          </MenuLine>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link to="/vannsport/">
                          <MenuLine>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4 mr-2 inline"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"
                              />
                            </svg>
                            Vannsport
                          </MenuLine>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link to="/guiding/">
                          <MenuLine>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4 mr-2 inline"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                              />
                            </svg>
                            Guiding
                          </MenuLine>
                        </Link>
                      )}
                    </Menu.Item>
                    <hr />
                    <Menu.Item>
                      {({ active }) => (
                        <Link to="/galleri/" className="text-yellow-600">
                          <MenuLineMuted>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4 mr-2 inline"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                            </svg>
                            Galleri
                          </MenuLineMuted>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link to="/kontakt/" className="text-yellow-600">
                          <MenuLineMuted>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4 mr-2 inline"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                              />
                            </svg>
                            kontakt
                          </MenuLineMuted>
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </div>{" "}
                
              </div>
            )}
          </Menu>
        </div>
      </div>
      {/* Heading End */}
      {/* Content */}
      <div className="flex-grow">
        <div>
          <div className="container mx-auto px-4">{children}</div>
        </div>
      </div>
      {/* Content End */}
      {/* Footer */}
      <div className="flex-none bg-yellow-900 h-48 flex items-end justify-center p-4">
        <p className="text-white font-semibold text-center">
          &copy; Jølster Adventure 2021
        </p>
      </div>

      {/* Footer End */}
    </div>
  )
}

export default Layout
