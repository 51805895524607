import React from "react"
import { Link } from "gatsby"

function MenuLarge({ children }) {
  return (
    <div className="items-center  hidden md:flex py-4 justify-between w-full text-lg">
      <div className="text-yellow-600">
        <Link className="px-1 pl-5  transform hover:rotate-6" to="/stisykling/">
          <span className="hover:underline inline-block transform hover:-translate-y-0.5 hover:-translate-x-0.5 transition duration-500 ease-in-out">Stisykling</span>
        </Link>
        <Link className="px-1 " to="/topptur/">
        <span className="hover:underline inline-block transform hover:-translate-y-0.5 hover:-translate-x-0.5 transition duration-500 ease-in-out">Topptur</span>
        </Link>
        <Link className="px-1 " to="/vannsport/">
        <span className="hover:underline inline-block transform hover:-translate-y-0.5 hover:-translate-x-0.5 transition duration-500 ease-in-out">Vannsport</span>
        </Link>
        <Link className="px-1 " to="/guiding/">
        <span className="hover:underline inline-block transform hover:-translate-y-0.5 hover:-translate-x-0.5 transition duration-500 ease-in-out">Guiding</span>
        </Link>
      </div>     
      <div className="text-green-50">
        {/* <Link
          className="text-sm bg-green-800 hover:bg-green-700 text-green-50 mx-1 px-3 py-1 rounded"
          to="/galleri/"
        >
          Galleri
        </Link> */}
        <Link
          className="text-sm bg-green-800 hover:bg-green-700 text-green-50 mx-1 px-3 py-1 rounded"
          to="/kontakt/"
        >
          Kontakt
        </Link>
      </div>
    </div>
  )
}

export default MenuLarge
