import React from "react"

function MenuLine({children}) {
    return (
        <div
            className="hover:text-white hover:bg-yellow-600 px-16 py-1 rounded hover:shadow"
        >
            {children}
        </div>
    )
}

export default MenuLine