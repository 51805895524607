import React from "react"

function Logo({ children }) {
  return (
    <div className="group">
      <div
        class="h-14 flex text-xl items-center py-3 font-semibold text-green-800 group-hover:text-green-600 filter drop-shadow-xl w-52"
       
      >
        <svg
          class="h-9 w-9 text-yellow-700 group-hover:text-yellow-500 mr-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        <span>{children}</span>
      </div>
    </div>
  )
}

export default Logo
